export const animations = {
  fade: {
    animate: 'enter',
    exit: 'exit',
    initial: 'exit',
    variants: {
      enter: {
        opacity: 1,
      },
      exit: {
        opacity: 0,
      },
    },
  },

  submenuWrap: {
    animate: 'enter',
    exit: 'exit',
    initial: 'exit',
    transition: {
      duration: 0.15,
      easings: 'linear',
    },
    variants: {
      enter: {
        height: 'auto',
        opacity: 1,
        // transition: {
        //   opacity: {
        //     duration: 0.1,
        //     easings: 'linear',
        //   },
        // },
      },
      exit: {
        height: 0,
        opacity: 0,
        // transition: {
        //   opacity: {
        //     duration: 0.1,
        //     easings: 'linear',
        //   },
        // },
      },
    },
  },

  submenuItem: {
    animate: 'enter',
    exit: 'exit',
    initial: 'exit',
    transition: {
      duration: 0.15,
      easings: 'linear',
    },
    variants: {
      enter: {
        translateY: '0px',
      },
      exit: {
        translateY: '-20%',
      },
    },
  },
};
