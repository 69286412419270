import { useQueriesData } from './use-queries-data';

export function getPermission(grants: string[], list: Record<string, Record<string, boolean>>) {
  if (!grants) return false;

  if (!grants[1]) {
    return list?.[grants[0]];
  }

  return list?.[grants[0]][grants[1]];
}

export const usePermission = (grants: string[]) => {
  const { accessListQueryData } = useQueriesData();

  if (!grants.length) {
    throw new Error('usePermission() must have at least 1 parameter');
  }

  return getPermission(grants, accessListQueryData);
};

export const useSomePermissions = (grants: [string, string][]) => {
  const { accessListQueryData } = useQueriesData();

  if (!grants.length) {
    throw new Error('useSomePermissions() must have at least 2 parameter');
  }

  const boolList = grants.map((g) => getPermission(g, accessListQueryData));

  return boolList.some(Boolean);
};
