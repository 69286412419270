import { useCallback, useState } from 'react';

import type { Dispatch, SetStateAction } from 'react';

export type UseBooleanReturn = {
  setFalse: () => void;
  setTrue: () => void;
  setValue: Dispatch<SetStateAction<boolean>>;
  toggle: () => void;
  value: boolean;
};

export function useBoolean(defaultValue = false): UseBooleanReturn {
  if (typeof defaultValue !== 'boolean') {
    throw new TypeError('defaultValue must be `true` or `false`');
  }
  const [value, setValue] = useState(defaultValue);

  const setTrue = useCallback(() => {
    setValue(true);
  }, []);

  const setFalse = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue((x) => !x);
  }, []);

  return { value, setValue, setTrue, setFalse, toggle };
}
