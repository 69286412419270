import { PropsWithChildren, useEffect, useId } from 'react';

import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';

import { animations } from '../../../../constants/animations';
import { RouteWithSubMenuProps } from '../../../../constants/routes';
import { useBoolean } from '../../../../hooks/use-boolean';
import { CustomIcon } from '../../../custom-icon/custom-icon';
import { PermissionWrap, SomePermissionWrap } from '../../../permission-wrap/permission-wrap';

export interface MainMenuNavLinkProps extends RouteWithSubMenuProps {
  activeAccordionItemId: string | null;
  hideMenu: () => void;
  menuCollapseControl: ReturnType<typeof useBoolean>;
  onChangeActiveItem: (id: string) => void;
  onSubMenuLinkClick: () => void;
}

export const MainMenuNavLink = ({
  path,
  icon,
  title,
  hideMenu,
  roleAccess,
  iconStroke,
  subMenu,
  activeAccordionItemId,
  onChangeActiveItem,
  onSubMenuLinkClick,
  menuCollapseControl,
}: MainMenuNavLinkProps) => {
  if (subMenu && subMenu.length) {
    return (
      <SubMenuLinkWrapper
        activeId={activeAccordionItemId}
        hideMenu={hideMenu}
        icon={icon}
        menuCollapseControl={menuCollapseControl}
        onChange={onChangeActiveItem}
        onSubMenuLinkClick={onSubMenuLinkClick}
        roleAccess={roleAccess}
        subMenu={subMenu}
        title={title}
      />
    );
  }

  if (!path || !roleAccess) return null;

  return (
    <SomePermissionWrap grants={roleAccess}>
      <NavLink
        key={path}
        to={path}
        className={({ isActive }) =>
          cn(
            'aside-menu-navlink',
            { '!ml-auto !justify-center !size-fit': menuCollapseControl.value },
            { _active: isActive }
          )
        }
      >
        <div
          className={cn('grid grid-cols-[auto_1fr] items-center', {
            '!flex justify-center': menuCollapseControl.value,
          })}
        >
          <CustomIcon
            {...(iconStroke && { stroke: iconStroke })}
            className='text-[#7A7F85] size-5'
            name={icon as string}
          />

          {!menuCollapseControl.value && <span className='ml-4'>{title}</span>}
        </div>
      </NavLink>
    </SomePermissionWrap>
  );
};

export interface SubMenuLinkWrapper extends Partial<MainMenuNavLinkProps> {
  activeId: string | null;
  hideMenu: () => void;
  menuCollapseControl: ReturnType<typeof useBoolean>;
  onChange: (id: string) => void;
  onSubMenuLinkClick: () => void;
}

const SubMenuLinkWrapper = ({
  subMenu,
  title,
  icon,
  iconStroke,
  roleAccess,
  onSubMenuLinkClick,
  onChange,
  activeId,
  hideMenu,
  menuCollapseControl,
}: SubMenuLinkWrapper) => {
  const { pathname } = useLocation();
  const id = useId();

  const isActive = activeId === id;

  const onValueIdChange = () => {
    if (menuCollapseControl.value) {
      hideMenu();
    }
    onChange(id);
  };

  useEffect(() => {
    if (!subMenu?.length) return;
    const setPaths = new Set(subMenu.map(({ path }) => path));

    if (setPaths.has(pathname)) {
      onValueIdChange();
    }
  }, []);

  function getActiveSubItem() {
    return subMenu?.find((el) => pathname.startsWith(el.path ?? ''));
  }

  function getCollapseActiveIcon(): string {
    if (!menuCollapseControl.value && icon) {
      return icon;
    }

    const item = getActiveSubItem();

    return String(item?.icon || icon);
  }

  if (!subMenu || !subMenu.length || !roleAccess) return null;

  return (
    <SomePermissionWrap grants={roleAccess}>
      <button
        onClick={onValueIdChange}
        className={cn(
          'aside-menu-navlink w-full',
          { '!ml-auto !justify-end !size-fit': menuCollapseControl.value },
          { '_active _sub': getActiveSubItem() && menuCollapseControl.value }
        )}
      >
        <div className='inline-flex items-center'>
          <CustomIcon
            {...(iconStroke && { stroke: iconStroke })}
            className='h-5 w-5 text-[#7A7F85]'
            name={getCollapseActiveIcon()}
          />

          {!menuCollapseControl.value && <span className='ml-4'>{title}</span>}
        </div>

        {!menuCollapseControl.value && (
          <CustomIcon
            className={cn('transition duration-150', { 'rotate-x-180': isActive })}
            height={6}
            name='anchor-brick'
            width={10}
          />
        )}
      </button>

      <AnimatePresence>
        {isActive && (
          <motion.div {...animations.submenuWrap} className='relative'>
            <div className='absolute top-0 bottom-[22.5px] left-4 w-0.5 bg-white' />

            {subMenu.map((item, index) => {
              if (!item.path) return null;

              const AccessControl = ({ children }: PropsWithChildren) => {
                if (item.someRoleAccess) {
                  return <SomePermissionWrap grants={item.someRoleAccess}>{children}</SomePermissionWrap>;
                }

                if (item.roleAccess) {
                  return <PermissionWrap grants={item.roleAccess}>{children}</PermissionWrap>;
                }

                return <></>;
              };

              return (
                <AccessControl key={item.path}>
                  <motion.div key={item.path} className='relative w-full pl-[30px]'>
                    <svg
                      className='left-4 pos-abs-y'
                      fill='none'
                      height='7'
                      viewBox='0 0 7 7'
                      width='7'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M1 1V2C1 4.20914 2.79086 6 5 6H6' stroke='white' strokeLinecap='round' strokeWidth='2' />
                    </svg>

                    <NavLink
                      onClick={onSubMenuLinkClick}
                      to={item.path}
                      className={({ isActive }) =>
                        cn(
                          'aside-menu-navlink',
                          { '!ml-auto !justify-center !size-fit': menuCollapseControl.value },
                          { _active: isActive }
                        )
                      }
                    >
                      <div className='grid grid-cols-[auto_1fr]'>
                        <CustomIcon
                          {...(item.iconStroke && { stroke: item.iconStroke })}
                          className='h-5 w-5 text-[#7A7F85]'
                          name={item.icon as string}
                        />

                        <span className='ml-4 text-ellipsis whitespace-nowrap'>{item.title}</span>
                      </div>
                    </NavLink>
                  </motion.div>
                </AccessControl>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </SomePermissionWrap>
  );
};
