import { PropsWithChildren } from 'react';

import { usePermission, useSomePermissions } from '../../hooks/use-permission';

interface PermissionWrapProps {
  grants: [string, string];
}

interface SomePermissionWrapProps {
  grants: [string, string][];
}

export const PermissionWrap = ({ children, grants }: PropsWithChildren<PermissionWrapProps>) => {
  const hasPermission = usePermission(grants);

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};

export const SomePermissionWrap = ({ children, grants }: PropsWithChildren<SomePermissionWrapProps>) => {
  const hasPermission = useSomePermissions(grants);

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};
